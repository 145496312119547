.Peepo.isSpeaking {
  animation: bop 7s infinite;
}

@keyframes bop {
  0% {
    transform: translateY(0);
  }
  2.38% {
    transform: translateY(-6px);
  }
  5.38% {
    transform: translateY(0px);
  }
  34.13% {
    transform: translateY(0px);
  }
  36.51% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
