.peepoConfigInput input {
  color: #00b5ad !important;
  background: #1b1c1d !important;
}
body {
  background: black;
}

.details {
  font-size: 14px;
}
